import React from "react";
import { useTheme } from "../../ThemeContext";
import "./AboutMst.scss";
import { CurvedLine, CurvedLine2 } from "../../Icons";

export default function AboutMst() {
  // darkmode light mode
  const { isDarkMode } = useTheme();
  const [mstPrice, setMstPrice] = React.useState(0);
  const [circulatingSupply, setCirculatingSupply] = React.useState("0");
  const [marketCap, setMarketCap] = React.useState(0);

  // Define the function to calculate the market cap
  // Define the calculateMarketCap function
  function formatNumberWithCommas(number) {
    return Math.floor(number).toLocaleString();
  }

  const calculateMarketCap = (circulatingSupply, mstPrice) => {
    const supply = parseFloat(circulatingSupply.slice(0, 7));

    if (!isNaN(supply) && !isNaN(mstPrice)) {
      return formatNumberWithCommas(supply * mstPrice);
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    const getMstPrice = async () => {
      try {
        const response = await fetch(
          "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/mst_price"
        );
        const priceMST = await response.json();
        console.log(priceMST, "MST PRICE")
        setMstPrice(priceMST)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getMstPrice();
  }, []);


  React.useEffect(() => {
    const getCirculatingSupply = async () => {
      const response = await fetch(
        "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/mst_supply"
      );
      const data = await response.text();
      setCirculatingSupply(data);
    };
    getCirculatingSupply();
  }, []);


  React.useEffect(() => {
    const calculatedMarketCap = calculateMarketCap(circulatingSupply, mstPrice);
    setMarketCap(calculatedMarketCap);
  }, [circulatingSupply, mstPrice]);

  return (
    <section id="about-mst-area">
      <div className="container">
        <div className={`${isDarkMode ? "dark" : "light"}`}>
          <div className="about-mst-content-wrapper">
            <div className="blur-shape">
              <h2>Everything you need to know about MST</h2>
              <p>
                Meridian Stability Token (MST) is the core and native token
                issued by the Meridian protocol. It captures fee revenue that is
                generated by the system and incentivizes early adopters.
              </p>

              <div className="mst-flex-content">
                <div className="img-box">
                  <img
                    className="img-fluid circlecoin"
                    src="assets/about-mst/mst.jpg"
                    alt=""
                    height="220" width="220"
                  />
                  <div className="verticle-line"></div>
                </div>

                {/* ... */}
                <div className="text-img-content">
                  <div className="item">
                    <div className="curved1">
                      <CurvedLine color={isDarkMode ? "#8692d0" : "#000"} />
                    </div>

                    <div className="img-text-box">
                      <img
                        src={`assets/about-mst/${isDarkMode ? "number-10-d.svg" : "number-10.svg"
                          }`}
                        alt=""
                      />
                      <p style={{ width: "231px", marginRight: "10px" }}>
                        MST token supply is capped at 10M tokens across all
                        chains.
                      </p>
                    </div>
                    <div className="line90"></div>
                    <div className="img-text-box">
                      <img
                        src={`assets/about-mst/${isDarkMode ? "discount-d.svg" : "discount.svg"
                          }`}
                        alt=""
                      />
                      <p>
                        Stake MSTs to earn 100% of fees earned by USDMs getting
                        minted or redemptions.
                      </p>
                    </div>
                  </div>

                  {/* .. */}
                  <div className="item">
                    <div className="line100"></div>
                    <div className="img-text-box">
                      <img
                        src={`assets/about-mst/${isDarkMode ? "profit-d.svg" : "profit.svg"
                          }`}
                        alt=""
                      />
                      <p
                        style={{
                          width: "230px",
                          marginRight: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Earn MST emissions by buying MLP tokens and providing
                        liquidity to the trading platform.
                      </p>
                    </div>
                    <div className="line90"></div>
                    <div className="img-text-box">
                      <img
                        src={`assets/about-mst/${isDarkMode ? "profit-d.svg" : "profit.svg"
                          }`}
                        alt=""
                      />
                      <p style={{ paddingRight: "9px" }}>
                        Superstake MSTs to earn 30% of Meridian Trade
                        accumulated fees as well a bonus multiplier points
                        to futher enhance your long-term reward
                      </p>
                    </div>
                  </div>
                  {/* .. */}
                  <div className="item">
                    <div className="curved2">
                      <CurvedLine2 color={isDarkMode ? "#8692d0" : "#000"} />
                    </div>

                    <div className="img-text-box">
                      <img
                        src={`assets/about-mst/${isDarkMode ? "statistics-d.svg" : "statistics.svg"
                          }`}
                        alt=""
                      />
                      <p style={{ width: "224px", marginRight: "17px" }}>
                        MST emissions are decreasing in declining curve, making
                        MST more scarce with time.
                      </p>
                    </div>

                    <div className="line90"></div>

                    <div className="img-text-box">
                      <img
                        src={`assets/about-mst/${isDarkMode ? "deposit-d.svg" : "deposit.svg"
                          }`}
                        alt=""
                      />
                      <p>
                        Earn MST emissions by depositing USDM to the stability
                        pool on the Base network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* .. */}
              <div className="box-groups">
                <div className="box">
                  <h6>$MST Price</h6>
                  <h4>${mstPrice}</h4>
                </div>

                <div className="box">
                  <h6>Circulating supply</h6>
                  <h4>
                    {parseInt(circulatingSupply.slice(0, 7)).toLocaleString()}
                  </h4>
                </div>
                <div className="box">
                  <h6>Marketcap</h6>
                  <h4>${marketCap}</h4>
                </div>
                <div className="box">
                  <h6>Max Supply</h6>
                  <h4>10,000,000</h4>
                </div>
              </div>

              {/* .. */}
              <div className="btn-groups">
                <button><a href="https://docs.meridianfinance.net/tokenomics" target="_blank" rel="noopener noreferrer">Read More</a></button>
                <button><a href="https://dexscreener.com/telos/0x205366d4243b21aeb8f4118e643466864b271c77" target="_blank" rel="noopener noreferrer">Track Price Performance</a></button>
                <button><a href="https://stake.meridianfinance.net/#/tokens" target="_self" rel="noopener noreferrer">Buy MST on Any Chain</a></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
