import React, { useRef } from "react";
import { useTheme } from "../../ThemeContext";
import { LeftArrow, RightArrow } from "../../Icons";
import "./Partner.scss";
import Slider from "react-slick";

const Partner = () => {
  // useref
  const sliderRef2 = useRef(null);
  // darkmode light mode
  const { isDarkMode } = useTheme();

  //slider
  const settings = {
    className: "left",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    ceterMode: false,
    initialSlide: 0,
    rows: 2,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  // next slide
  const nextSlide = () => {
    if (sliderRef2.current) {
      sliderRef2.current.slickNext();
    }
  };

  //prev slide
  const prevSlide = () => {
    if (sliderRef2.current) {
      sliderRef2.current.slickPrev();
    }
  };

  const partners = [
    {
      img: "assets/partner/img1.svg",
      imgd: "assets/partner/d-img1.svg",
    },
    {
      img: "assets/partner/img2.svg",
      imgd: "assets/partner/d-img2.svg",
    },
    {
      img: "assets/partner/img3.svg",
      imgd: "assets/partner/d-img3.svg",
    },
    {
      img: "assets/partner/img4.svg",
      imgd: "assets/partner/d-img4.svg",
    },
    {
      img: "assets/partner/img5.svg",
      imgd: "assets/partner/d-img5.svg",
    },
    {
      img: "assets/partner/swapsicle0.png",
      imgd: "assets/partner/swapsicle.jpg",
    },
    {
      img: "assets/partner/img1.svg",
      imgd: "assets/partner/d-img1.svg",
    },
    {
      img: "assets/partner/img2.svg",
      imgd: "assets/partner/d-img2.svg",
    },
    {
      img: "assets/partner/img3.svg",
      imgd: "assets/partner/d-img3.svg",
    },
    {
      img: "assets/partner/img4.svg",
      imgd: "assets/partner/d-img4.svg",
    },
    {
      img: "assets/partner/img5.svg",
      imgd: "assets/partner/d-img5.svg",
    },
    {
      img: "assets/partner/swapsicle0.png",
      imgd: "assets/partner/swapsicle.jpg",
    },
  ];
  return (
    <section id="partner-area">
      <div className="container">
        <div className={`${isDarkMode ? "dark" : "light"}`}>
          <div className="partner-content-wrapper">
            <div className="slide-text-content">
              <div className="slide-content">
                <Slider {...settings} ref={sliderRef2}>
                  {partners.map((partner, i) => (
                    <div key={i}>
                      <div className="partner-card">
                        <div className="img-wrapper">
                          <img
                            className="img-fluid"
                            src={isDarkMode ? partner?.imgd : partner?.img}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="text-content">
                <h1>Partners</h1>
                <p>
                  Explore the synergies with leading projects that propel us
                  forward, shaping a future defined by excellence and shared
                  achievements.
                </p>

                <div className="arrow-groups">
                  <button onClick={prevSlide}>
                    <LeftArrow />
                  </button>
                  <button onClick={nextSlide}>
                    <RightArrow />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;
