import { useEffect } from "react";
import "./Common.scss";
import { useTheme } from "./ThemeContext";
import TradePage from "./Pages/TradePage";
import HomePage from "./Pages/HomePage";
import { ToggleProvider } from './Components/Trade/ToggleContext';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  const { isDarkMode } = useTheme();

  // Set body background color based on mode
  useEffect(() => {
    document.body.style.backgroundColor = isDarkMode ? "#2F3349" : "#ffffff";
  }, [isDarkMode]);

  return (
    <Router>
      <ToggleProvider>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/tokens" component={TradePage} />
        </Switch>
      </ToggleProvider>
    </Router>
  );
}

export default App;
