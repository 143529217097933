// 64d38d
export const MoonStars = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0005 3.01923C12.1325 3.01923 12.2635 3.01923 12.3935 3.01923C9.74263 5.4824 9.22851 9.48708 11.1712 12.54C13.1139 15.5928 16.9594 16.8233 20.3135 15.4652C18.5808 19.6344 14.0273 21.8745 9.66711 20.7029C5.30692 19.5313 2.48956 15.3105 3.07992 10.8344C3.67027 6.35832 7.48562 3.01232 12.0005 3.01123V3.01923Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0005 3.01923C12.1325 3.01923 12.2635 3.01923 12.3935 3.01923C9.74263 5.4824 9.22851 9.48708 11.1712 12.54C13.1139 15.5928 16.9594 16.8233 20.3135 15.4652C18.5808 19.6344 14.0273 21.8745 9.66711 20.7029C5.30692 19.5313 2.48956 15.3105 3.07992 10.8344C3.67027 6.35832 7.48562 3.01232 12.0005 3.01123V3.01923Z"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 4.01929C17 5.12386 17.8954 6.01929 19 6.01929C17.8954 6.01929 17 6.91472 17 8.01929C17 6.91472 16.1046 6.01929 15 6.01929C16.1046 6.01929 17 5.12386 17 4.01929"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 4.01929C17 5.12386 17.8954 6.01929 19 6.01929C17.8954 6.01929 17 6.91472 17 8.01929C17 6.91472 16.1046 6.01929 15 6.01929C16.1046 6.01929 17 5.12386 17 4.01929"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10.0193C18.4477 10.0193 18 10.467 18 11.0193C18 11.5716 18.4477 12.0193 19 12.0193V10.0193ZM21 12.0193C21.5523 12.0193 22 11.5716 22 11.0193C22 10.467 21.5523 10.0193 21 10.0193V12.0193ZM21 10.0193C21 9.467 20.5523 9.01929 20 9.01929C19.4477 9.01929 19 9.467 19 10.0193H21ZM19 12.0193C19 12.5716 19.4477 13.0193 20 13.0193C20.5523 13.0193 21 12.5716 21 12.0193H19ZM19 12.0193H21V10.0193H19V12.0193ZM19 10.0193V12.0193H21V10.0193H19Z"
        fill={color}
      />
      <path
        d="M19 10.0193C18.4477 10.0193 18 10.467 18 11.0193C18 11.5716 18.4477 12.0193 19 12.0193V10.0193ZM21 12.0193C21.5523 12.0193 22 11.5716 22 11.0193C22 10.467 21.5523 10.0193 21 10.0193V12.0193ZM21 10.0193C21 9.467 20.5523 9.01929 20 9.01929C19.4477 9.01929 19 9.467 19 10.0193H21ZM19 12.0193C19 12.5716 19.4477 13.0193 20 13.0193C20.5523 13.0193 21 12.5716 21 12.0193H19ZM19 12.0193H21V10.0193H19V12.0193ZM19 10.0193V12.0193H21V10.0193H19Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export const CartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <circle
        cx="4.5"
        cy="14.2693"
        r="1.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12.75"
        cy="14.2693"
        r="1.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 12.7693H4.5V2.26929H3"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 3.76929L15 4.51929L14.25 9.76929H4.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LockedShape = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 8C38.2288 13.5109 46.3585 16.3802 54.6666 16C57.1105 24.3134 56.0784 33.2625 51.8064 40.8014C47.5343 48.3403 40.3876 53.8244 32 56C23.6123 53.8244 16.4656 48.3403 12.1935 40.8014C7.9215 33.2625 6.88943 24.3134 9.33329 16C17.6414 16.3802 25.7711 13.5109 32 8Z"
        fill={fill}
      />
      <path
        d="M32 8C38.2288 13.5109 46.3585 16.3802 54.6666 16C57.1105 24.3134 56.0784 33.2625 51.8064 40.8014C47.5343 48.3403 40.3876 53.8244 32 56C23.6123 53.8244 16.4656 48.3403 12.1935 40.8014C7.9215 33.2625 6.88943 24.3134 9.33329 16C17.6414 16.3802 25.7711 13.5109 32 8"
        stroke="#7367F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 8C38.2288 13.5109 46.3585 16.3802 54.6666 16C57.1105 24.3134 56.0784 33.2625 51.8064 40.8014C47.5343 48.3403 40.3876 53.8244 32 56C23.6123 53.8244 16.4656 48.3403 12.1935 40.8014C7.9215 33.2625 6.88943 24.3134 9.33329 16C17.6414 16.3802 25.7711 13.5109 32 8"
        stroke="#ADA3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="31.9997"
        cy="29.3333"
        r="2.66667"
        stroke="#7367F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="31.9997"
        cy="29.3333"
        r="2.66667"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 32V38.6667"
        stroke="#ADA3FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 32V38.6667"
        stroke="#8273FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
    >
      <circle
        cx="24.402"
        cy="18.6667"
        r="10.6667"
        stroke="#28C76F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="24.402"
        cy="18.6667"
        r="10.6667"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.40234 56V50.6667C8.40234 44.7756 13.178 40 19.069 40H29.7357C35.6267 40 40.4023 44.7756 40.4023 50.6667V56"
        stroke="#28C76F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.40234 56V50.6667C8.40234 44.7756 13.178 40 19.069 40H29.7357C35.6267 40 40.4023 44.7756 40.4023 50.6667V56"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0693 8.34634C47.7891 9.5548 51.0903 13.8077 51.0903 18.6797C51.0903 23.5517 47.7891 27.8046 43.0693 29.013"
        stroke="#28C76F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0693 8.34634C47.7891 9.5548 51.0903 13.8077 51.0903 18.6797C51.0903 23.5517 47.7891 27.8046 43.0693 29.013"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4023 55.9997V50.6664C56.3745 45.8253 53.0899 41.61 48.4023 40.3997"
        stroke="#28C76F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4023 55.9997V50.6664C56.3745 45.8253 53.0899 41.61 48.4023 40.3997"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TrovesIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <rect width="64" height="64" fill={fill} />
      <path
        d="M8 56H56"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 56H56"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 26.6667H56"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 26.6667H56"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 16L31.9997 8L50.6663 16"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 16L31.9997 8L50.6663 16"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6663 26.6667V56"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6663 26.6667V56"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.3333 26.6667V56"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.3333 26.6667V56"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.333 37.3333V45.3333"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.333 37.3333V45.3333"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 37.3333V45.3333"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 37.3333V45.3333"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.667 37.3333V45.3333"
        stroke="#00CFE8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.667 37.3333V45.3333"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const VolumeIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.667 50.6667L21.3337 34.6667L32.0003 40L42.667 26.6667L53.3337 37.3333V50.6667H10.667Z"
        fill={fill}
      />
      <path
        d="M10.667 50.6667L21.3337 34.6667L32.0003 40L42.667 26.6667L53.3337 37.3333V50.6667H10.667"
        stroke="#FF9F43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 50.6667L21.3337 34.6667L32.0003 40L42.667 26.6667L53.3337 37.3333V50.6667H10.667"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 32L18.667 21.3333L29.3337 26.6667L42.667 10.6667L53.3337 21.3333"
        stroke="#FF9F43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 32L18.667 21.3333L29.3337 26.6667L42.667 10.6667L53.3337 21.3333"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CurvedLine = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125"
      height="44"
      viewBox="0 0 125 44"
      fill="none"
    >
      <path d="M1 43L29.5 0.5H124.5" stroke={color} />
    </svg>
  );
};
export const CurvedLine2 = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="44"
      viewBox="0 0 124 44"
      fill="none"
    >
      <path d="M0.5 1L29 43.5H124" stroke={color} />
    </svg>
  );
};
export const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M8.51172 5.5L14.0117 11L8.51172 16.5"
        stroke="#7367F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M14.0117 5.5L8.51172 11L14.0117 16.5"
        stroke="#7367F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
// export const TrovesIcon = ()=>{
//   return()
// }
