import React, { useRef } from "react";
import "./Updates.scss";
import { useTheme } from "../../ThemeContext";
import { LeftArrow, RightArrow } from "../../Icons";
import Slider from "react-slick";

export default function Updates() {
  // useref
  const sliderRef = useRef(null);
  // darkmode light mode
  const { isDarkMode } = useTheme();

  //slider
  const settings = {
    // className: "left",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    ceterMode: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  // next slide
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  //prev slide
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const reviews = [
    {
      img: "assets/update/img3.png",
      description:
        "“With the resurgence of the Telos DeFi ecosystem, Meridian has decided to join the movement, by deploying our USDM stablecoin natively on Telos, thus providing users of the network a fully trustless and decentralized stablecoin option...”",
      time: "26/11/2023",
      link: "https://medium.com/@meridianFinance/meridian-expansion-to-telos-2afe926aa683",
    },
    {
      img: "assets/update/img2.png",
      description:
        "We are thrilled to share a significant development with our community. On November 23rd, at 5:00 pm UTC, Meridian will be making a strategic move by shifting our MST-ETH liquidity from Uniswap to Aerodrome! This transition is a crucial step...",
      time: "17/11/2023",
      link: "https://medium.com/@meridianFinance/unveiling-our-enhanced-stablecoin-interface-and-liquidity-migration-5fe4dbc9e240",
    },
    {
      img: "assets/update/img4.png",
      description:
        "“We are over thrilled to announce our latest partnership with Telos EVM, marking a pivotal moment in our journey to interoperability and cross-chain functionality. This collaboration will enable our stablecoin to be minted natively on Telos EVM...”",
      time: "27/10/2023",
      link: "https://medium.com/@meridianFinance/meridian-october-newsletter-07b2820f18e6",
    },
  ];
  return (
    <section id="update-area">
      <div className="container">
        <div className={`${isDarkMode ? "dark" : "light"}`}>
          <div className="update-content-wrapper">
            <div className="text-slide-content">
              <div className="text-content">
                <h1>Recent updates </h1>
                <p>
                  To stay informed about our progress, we recommend following
                  our monthly reports and reading the abundance of exciting
                  announcements.
                </p>

                <div className="arrow-groups">
                  <button onClick={prevSlide}>
                    <LeftArrow />
                  </button>
                  <button onClick={nextSlide}>
                    <RightArrow />
                  </button>
                </div>
              </div>
              <div className="slide-content">
                <Slider {...settings} ref={sliderRef}>
                  {reviews.map((review, i) => (
                    <div key={i} className="my-3">
                      <div className="review-item-wrapper">
                        <a href={review.link} target="_blank" rel="noopener noreferrer">
                          <div className="img-wrapper">
                            <img className="img-fluid" src={review?.img} alt="" />
                            <p style={{ fontSize: 'smaller' }}>{review.description}</p>
                            <h5 style={{ fontSize: 'smaller' }}>{review.time}</h5>
                          </div>
                        </a>
                      </div>
                    </div>

                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
