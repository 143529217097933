import React from "react";
import { useTheme } from "../../ThemeContext";
import "./Faq.scss";
import { Accordion } from "react-bootstrap";

export default function Faq() {
  // darkmode light mode
  const { isDarkMode } = useTheme();

  return (
    <section id="faq-area">
      <div className="container">
        <div className={`${isDarkMode ? "dark" : "light"}`}>
          <div className="faq-content-wrapper">
            <div className="faq-box">
              <span>FAQ</span>
            </div>
            <h2>
              Frequently asked <div>questions</div>
            </h2>
            <h6>
              Browse through these FAQs to find answers to commonly asked
              questions.
            </h6>

            <div className="faq-img-accordion">
              <div className="img-content">
                <img className="img-fluid" src="assets/faq/img.png" alt="" />
              </div>

              <div className="accordion-box">
                <Accordion defaultActiveKey={1}>
                  <Accordion.Item eventKey={1}>
                    <Accordion.Header>What is Meridian?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        {" "}
                        Meridian is a non-custodial, decentralised financial
                        trading platform that offers interest-free stable coin
                        borrowing, leverage trading and zero slippage swaps all
                        in one place. With Meridian, users can trade whitelisted
                        crypto assets with up to 50x leverage, obtain
                        interest-free overcollateralised loans against ETH and
                        earn interest, fees and rewards for supporting the
                        protocol.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={2}>
                    <Accordion.Header>
                      What Are the Main Benefits of Meridian?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Meridian offers the best borrowing and trading
                        conditions on the market with the main benefits being:
                        <br />
                        <ul>
                          <li>- 0% interest rate loans</li>
                          <li>- 50x Leverage Trading</li>
                          <li>- Zero-Slippage Swaps</li>
                        </ul>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={3}>
                    <Accordion.Header>
                      How can Meridian offer interest-free borrowing?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Meridian charges a one-time borrowing and redemption fee
                        that algorithmically adjusts to ensure that the USD peg
                        is maintained. For example: If more redemptions are
                        happening (which means USDM is likely trading at less
                        than 1 USD), then the borrowing fee would continue to
                        increase, discouraging further borrowing.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={4}>
                    <Accordion.Header>What is USDM?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        USDM is Meridain's USD-pegged stablecoin. The ability to
                        redeem USDM for ETH at face value (i.e. 1 USDM for $1 of
                        ETH) and the minimum collateral ratio of 110% create a
                        price floor and price ceiling (respectively) through
                        arbitrage opportunities. We call these "hard peg
                        mechanisms" since they are based on direct processes.
                        USDM also benefits from less direct mechanisms for USD
                        parity — called "soft peg mechanisms". One of these
                        mechanisms is parity as a Schelling point. Since
                        Meridian treats USDM as being equal to USD, parity
                        between the two is an implied equilibrium state of the
                        protocol. Another of these mechanisms is the borrowing
                        fee on new debts. As redemptions increase (implying USDM
                        is below $1), so too does the baseRate — making
                        borrowing less attractive which keeps new USDM from
                        hitting the market and driving the price below $1.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={5}>
                    <Accordion.Header>What is MST?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Meridian Stability Token (MST) is the secondary token
                        issued by the Meridian protocol. It captures the fee
                        revenue that is generated by the system and incentivizes
                        early adopters. MST is a non-inflationary ERC20 token
                        with a maximum total supply of 10,000,000 MST rewards
                        will only accrue to Stability Providers — i.e. users who
                        deposit USDM to the Stability Pool, and liquidity
                        providers of the USDM:ETH liquidity pool. As technical
                        rewards, they are based on a preprogrammed functionality
                        of the protocol and not a claim towards protocol
                        developers or any third-party
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={6}>
                    <Accordion.Header>What is Meridian Trade?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Meridian Trade is a margin-based trading platform,
                        allowing traders to deposit a set percentage of the full
                        value of a trade in order to offer greater exposure to
                        the markets as compared to buying and holding the
                        underlying asset. Trading with margin (or leverage)
                        therefore amplifies both profits and losses equally.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
