import React from "react";
import { useTheme } from "../../ThemeContext";
import "./Footer.scss";
import { Link } from "react-router-dom";


const Footer = () => {
  // darkmode light mode
  const { isDarkMode } = useTheme();
  return (
    <footer id="footer-area">
      <div className="container">
        <div className={`${isDarkMode ? "dark" : "light"}`}>
          <div className="footer-content-wrapper">
            <div className="content-wrapper">

              <div className="left-side">
                <img src="assets/logo.svg" alt="" />
                <p>
                  {" "}
                  Building a composable suite of decentralised financial products on the top Layer 1 and Layer 2 networks.
                </p>
                {/*
                <span>Subscribe to newsletter</span>
                <div className="input-box">
                  <input placeholder="Your email" type="text" />
                  <button>Subscribe</button>
                </div>
                */}
              </div>

              <div className="link-groups">
                <div>
                  <h5>Pages</h5>
                  <ul>
                    <li>
                      <a href="https://docs.meridianfinance.net/" target="_blank">Documentation</a>
                    </li>
                    <li>
                      <a href="https://docs.meridianfinance.net/resources/legal" target="_blank">Terms of Service</a>
                    </li>
                    <li>
                      <a href="https://www.themeridianmarketingmachine.net/" target="_blank">Meridian Marketing Machine</a>
                    </li>
                  </ul>

                </div>
                <div>
                  <h5>Products</h5>
                  <ul>
                    <li>
                      <a href="https://mint.meridianfinance.net/#/" rel="noopener noreferrer">Meridian Mint</a>
                    </li>
                    <li>
                      <a href="https://lend.meridianfinance.net" rel="noopener noreferrer">Meridian Lend</a>
                    </li>
                    <li>
                      <a href="https://trade.meridianfinance.net/#/dashboard" rel="noopener noreferrer">Meridian Trade</a>
                    </li>

                  </ul>
                </div>
                <div>
                  <h5>Analytics</h5>
                  <ul>
                    <li>
                      <a href="https://defillama.com/protocol/meridian" target="_blank">DeFiLlama</a>
                    </li>
                    <li>
                      <a href="https://dexscreener.com/telos/0x205366d4243b21aeb8f4118e643466864b271c77" target="_blank">DexScreener</a>
                    </li>
                    <li>
                      <a href="https://debank.com/protocols/base_meridianfinance" target="_blank">DeBank</a>
                    </li>
                    <li>
                      <a href="https://dappradar.com/dapp/meridian-finance-2" target="_blank">DappRadar</a>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-links">
            <div className="bottom-link-groups">
              <a href="https://github.com/MeridianDollar" target="_blank">
                <img src="assets/footer/github.svg" alt="" />
              </a>
              <a href="https://medium.com/@meridianFinance" target="_blank">
                <img src="assets/footer/medium.svg" alt="" />
              </a>
              <a href="https://discord.com/invite/SCHCXz9Mnb" target="_blank">
                <img src="assets/footer/discord.svg" alt="" />
              </a>
              <a href="https://twitter.com/MeridianFi" target="_blank">
                <img src="assets/footer/twiter.svg" alt="" />
              </a>
              <a href="https://t.me/meridianFinance1" target="_blank">
                <img src="assets/footer/telegram.svg" alt="" />
              </a>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
