import React, { useState } from 'react';
import './Header.scss';
import { useTheme } from '../../ThemeContext';

const baseIMG = require('../../img/tokens/base.png');
const tlosIMG = require('../../img/tokens/tlos.png');
const fuseIMG = require('../../img/tokens/fuse.png');
const meterIMG = require('../../img/tokens/meter.png');




const Popup = ({ onClose, onProceed }) => (
  <div className="popup-container">
    <p className="popup-text">By proceeding to the Meridian app, you agree to Meridian's Terms of Use.</p>
    <div className="button-container">
      <button className="launch-button" onClick={onProceed}>Proceed</button>
      <button className="launch-button cancel-button" onClick={onClose}>Cancel</button>
    </div>
  </div>
);


const Header = () => {

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };


  const [showPopup, setShowPopup] = useState(false);

  const showPopupHandler = () => {
    setShowPopup(true);
  };

  const closePopupHandler = () => {
    setShowPopup(false);
  };


  const proceedToMeridianMint = () => {
    const externalSiteURL = 'https://mint.meridianfinance.net';
    window.open(externalSiteURL, '_self');

  };

  const proceedToMeridianLend = () => {
    const externalSiteURL = 'https://lend.meridianfinance.net';
    window.open(externalSiteURL, '_self');
  };

  const proceedToMeridianTrade = () => {
    const externalSiteURL = 'https://trade.meridianfinance.net'; // Use the full URL
    window.open(externalSiteURL, '_self');
  };

  const { isDarkMode } = useTheme();

  return (
    <header id="header-area">
      <div className="container">
        <div className={`${isDarkMode ? 'dark' : 'light'}`}>
          <div
            className="header-content"
            style={{
              background: isDarkMode
                ? '#1E2130'
                : 'linear-gradient(138deg,#B0D9E7 0%, #FAFCFD 94.44%)',
            }}
          >
            <div className="blur-shape">
              <h1>
                {!isDarkMode
                  ? `Forging a new era of sound money, secured algorithmically`
                  : `Forging a new era of sound money, secured algorithmically`}{' '}
              </h1>
              <div className="header-box-groups">
                <div className="box">
                  <img src="assets/hero/lend.png" alt="" width="110" height="110" />

                  <h4>Lending</h4>
                  <p>
                    Meridian Lend provides a decentralized money market,
                    connecting lenders with borrowers.
                    Lenders earn passive income by providing liquidity,
                    while borrowers secure overcollateralized loans.
                  </p>
                  <p>
                    <br />
                    <br />
                    <strong>Available on</strong> <br />
                    <img src={tlosIMG} alt="Second Logo" height="18" width="18" />
                    <img src={meterIMG} alt="Second Logo" height="18" width="18" />
                    <img src={fuseIMG} alt="Second Logo" height="18" width="18" />

                  </p>
                  <button onClick={proceedToMeridianLend}>Launch Meridian Lend</button>
                </div>
                <div className="box">
                  <img src="assets/hero/stablecoin.svg" alt="" width="110" height="110" />
                  <h4>Decentralized Stablecoin</h4>
                  <p>
                    Meridian Mint is a decentralized, non-custodial,
                    governance-free borrowing protocol that enables one to
                    obtain interest-free loans against whitelisted collateral.
                    Loans are paid in USDM - a USD-pegged stablecoin.
                  </p>
                  <p>
                    <br />
                    <strong>Available on</strong> <br />
                    <img src={baseIMG} alt="App Logo" height="16" width="16" />
                    <img src={tlosIMG} alt="Second Logo" height="18" width="18" />
                    <img src={fuseIMG} alt="Second Logo" height="16" width="16" />
                  </p>
                  <button onClick={proceedToMeridianMint}>Launch Meridian Mint</button>
                </div>
                <div className="box">
                <img src="assets/hero/perpetuals.svg" alt="" width="110" height="110" />
                  <h4>Leverage Trading Platform</h4>
                  <p>
                    Meridian Trade is the Leveraged DeFi trading platform within
                    the Meridian suite of products. Traders can use USDM for
                    derivatives trading to ensure that they stay fully decentralized.
                  </p>
                  <p>
                    <br />
                    <br />
                    <strong>Available on</strong> <br />
                    <img src={baseIMG} alt="App Logo" height="16" width="16" />
                    {/* <img src={tlosIMG} alt="Second Logo" height="18" width="18" /> */}
                  </p>
                  <button onClick={proceedToMeridianTrade}>Launch Meridian Trade</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
