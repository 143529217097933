import { useEffect } from "react";
import "../Common.scss";
import Header from "../Components/Header/Header";
import { useTheme } from "../ThemeContext";
import Statistics from "../Components/Statistics/Statistics";
import AboutMst from "../Components/AboutMst/AboutMst";
import Faq from "../Components/Faq/Faq";
import Team from "../Components/Team/Team";
import Updates from "../Components/Updates/Updates";
import Partner from "../Components/Partner/Partner";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Common/Navbar/Navbar";

const HomePage = () => {
    const { isDarkMode } = useTheme();
    // Set body background color based on mode
    useEffect(() => {
        document.body.style.backgroundColor = isDarkMode ? "#2F3349" : "#ffffff";
    }, [isDarkMode]);

    return (
        <>
            <Navbar />
            <Header />
            <Statistics />
            <AboutMst />
            <Faq />
            <Team />
            <Updates />
            <Partner />
            <Footer />
        </>
    );
}

export default HomePage;
