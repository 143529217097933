import React, { useContext } from 'react';
// import { BaseUSDMData } from "../../Data";
import image2 from "../../assets/Images/Voltage.png";
import { ToggleContext } from './ToggleContext';


const BaseTrade = () => {

  // darkmode light mode
  const [mstPriceVoltage, setMSTPriceVoltage] = React.useState(0);
  const [usdmPriceVoltage, setUSDMPriceVoltage] = React.useState(0);

  const [mstLiquidityVoltage, setMSTLiquidityVoltage] = React.useState(0);
  const [usdmLiquidityVoltage, setUSDMLiquidityVoltage] = React.useState(0);

  function formatNumberWithCommas(number) {
    return Math.floor(number).toLocaleString();
  }

  React.useEffect(() => {
    const getUSDMVoltageData = async () => {
      try {
        const response = await fetch("https://api.thegraph.com/subgraphs/name/voltfinance/exchange-v3", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              {
                pool(id: "0x633feb7c4e690c9a0c79541056bf53dd3adc1cf2") {
                  id
                  token0 {
                    symbol
                  }
                  token1 {
                    symbol
                  }
                  liquidityProviderCount
                  poolHourData(orderBy: periodStartUnix, orderDirection: desc, first: 1) {
                    periodStartUnix
                    tvlUSD
                    volumeUSD
                    token0Price
                    token1Price
                  }
                }
              }
            `
          })
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const liquidityUSDM = data.data.pool.poolHourData[0].tvlUSD;
        const USDMRatio = data.data.pool.poolHourData[0].token0Price;

        let priceFUSE = 0;
        try {
          const responseFUSE = await fetch("https://api.dexscreener.com/latest/dex/tokens/0x0BE9e53fd7EDaC9F859882AfdDa116645287C629");
          const dataFUSE = await responseFUSE.json();
          priceFUSE = dataFUSE.pairs[0].priceUsd;
        } catch (error) {
          console.error("Error fetching FUSE data:", error);
        }

        const USDMPrice = USDMRatio * priceFUSE
        const formattedUSDMLiquidity = formatNumberWithCommas(liquidityUSDM);

        setUSDMPriceVoltage(USDMPrice);
        setUSDMLiquidityVoltage(formattedUSDMLiquidity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUSDMVoltageData();
  }, []);


  React.useEffect(() => {
    const getMSTVoltageData = async () => {
      try {
        const response = await fetch("https://api.thegraph.com/subgraphs/name/voltfinance/exchange-v3", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              {
                pool(id: "0x74f32415d58bf23c3e7d8a9729035106700b857c") {
                  id
                  token0 {
                    symbol
                  }
                  token1 {
                    symbol
                  }
                  liquidityProviderCount
                  poolHourData(orderBy: periodStartUnix, orderDirection: desc, first: 1) {
                    periodStartUnix
                    tvlUSD
                    volumeUSD
                    token0Price
                    token1Price
                  }
                }
              }
            `
          })
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const liquidityMST = data.data.pool.poolHourData[0].tvlUSD;
        const MSTRatio = data.data.pool.poolHourData[0].token0Price;
        const formattedMSTLiquidity = formatNumberWithCommas(liquidityMST);

        let priceFUSE = 0;
        try {
          const responseFUSE = await fetch("https://api.dexscreener.com/latest/dex/tokens/0x0BE9e53fd7EDaC9F859882AfdDa116645287C629");
          const dataFUSE = await responseFUSE.json();
          priceFUSE = dataFUSE.pairs[0].priceUsd;
        } catch (error) {
          console.error("Error fetching FUSE data:", error);
        }
        const MSTPrice = MSTRatio * priceFUSE

        // Update state variables
        setMSTPriceVoltage(MSTPrice);
        setMSTLiquidityVoltage(formattedMSTLiquidity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMSTVoltageData();
  }, []); // Empty dependency array since it runs once after the component mounts





  const BaseUSDMData = [
    {
      img: image2,
      priceType: "USDM/FUSE",
      price: "$" + usdmPriceVoltage.toFixed(3),
      para: "Current Liquidity: $" + usdmLiquidityVoltage,
      button: "Trade Now",
      href: "https://voltage.finance/swap?outputCurrency=0x4447863cddABbF2c3dAC826f042e03c91927A196"
    },
  ];


  const BaseMSTData = [
    {
      img: image2,
      priceType: "MST/FUSE",
      price: "$" + mstPriceVoltage.toFixed(3),
      para: "Current Liquidity: $" + mstLiquidityVoltage,
      button: "Trade Now",
      href: "https://voltage.finance/swap?outputCurrency=0x2363Df84fDb7D4ee9d4E1A15c763BB6b7177eAEe"
    },
  ];

  const { toggle, setToggle } = useContext(ToggleContext);
  console.log(toggle, "IS TRUE")
  return (
    <div className="main">
      <div className="switchbtn">
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          MST
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            checked={toggle} // Set checked attribute based on toggle state
            onChange={() => setToggle(!toggle)} // Use onChange event to update toggle state
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            USDM
          </label>
        </div>
      </div>

      {toggle === true ? (

        <div className="tradeCards">
          {BaseUSDMData.map((e, i) => {
            return (
              <div className="card" key={i}>
                <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
                <p className="switchType">
                  {e.switch} {e.priceType}
                </p>
                <h1>{e.price}</h1>
                <p className="cardPara">{e.para}</p>
                <a
                  href={e.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>{e.button}</button>
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="tradeCards">
          {BaseMSTData.map((e, i) => {
            return (
              <div className="card" key={i}>
                <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
                <p className="switchType">
                  {e.switch} {e.priceType}
                </p>
                <h1>{e.price}</h1>
                <p className="cardPara">{e.para}</p>
                <a
                  href={e.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>{e.button}</button>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BaseTrade;
