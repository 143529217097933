import React, { useContext } from "react";
// import { BaseUSDMData } from "../../Data";
import image1 from "../../assets/Images/Swapsicle.png";
import image2 from "../../assets/Images/Symmetric.png";
import { ToggleContext } from './ToggleContext';


const BaseTrade = () => {

  // darkmode light mode
  const [mstPriceSwapsicle, setMSTPriceSwapsicle] = React.useState(0);
  const [usdmPriceSwapsicle1, setUSDMPriceSwapsicle1] = React.useState(0);
  const [usdmPriceSwapsicle2, setUSDMPriceSwapsicle2] = React.useState(0);

  const [mstLiquiditySwapsicle, setMSTLiquiditySwapsicle] = React.useState(0);
  const [usdmLiquiditySwapsicle1, setUSDMLiquiditySwapsicle1] = React.useState(0);
  const [usdmLiquiditySwapsicle2, setUSDMLiquiditySwapsicle2] = React.useState(0);

  const [usdmLiquiditySymmetric, setUSDMLiquiditySymmetric] = React.useState(0);
  const [usdmPriceSymmetric, setUSDMPriceSymmetric] = React.useState(0);

  const [mstPriceSymmetric, setMSTPriceSymmetric] = React.useState(0);
  const [mstLiquiditySymmetric, setMSTLiquiditySymmetric] = React.useState(0);



  function formatNumberWithCommas(number) {
    return Math.floor(number).toLocaleString();
  }

  React.useEffect(() => {
    const getUSDMSwapsicleData = async () => {
      try {
        const response = await fetch(
          "https://api.dexscreener.com/latest/dex/tokens/0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9"
        );
        const data = await response.json();

        // Accessing the priceNative field from the first pair
        const priceUSDM_USDC = data.pairs[0].priceUsd;
        const priceUSDM_TLOS = data.pairs[1].priceUsd;

        const liquidityUSDM_USDC = data.pairs[0].liquidity.usd;
        const liquidityUSDM_TLOS = data.pairs[1].liquidity.usd;

        const formattedUSDMLiquidity1 = formatNumberWithCommas(liquidityUSDM_USDC);
        const formattedUSDMLiquidity2 = formatNumberWithCommas(liquidityUSDM_TLOS);

        setUSDMPriceSwapsicle1(priceUSDM_TLOS);
        setUSDMPriceSwapsicle2(priceUSDM_USDC);

        setUSDMLiquiditySwapsicle1(formattedUSDMLiquidity1)
        setUSDMLiquiditySwapsicle2(formattedUSDMLiquidity2)


      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUSDMSwapsicleData();
  }, []);


  React.useEffect(() => {
    const getMSTSwapsicleData = async () => {
      try {
        const response = await fetch(
          "https://api.dexscreener.com/latest/dex/tokens/0x568524DA340579887db50Ecf602Cd1BA8451b243"
        );
        const data = await response.json();

        // Accessing the priceNative field from the first pair
        const priceMST = data.pairs[0].priceUsd;
        const liquidityMST = data.pairs[0].liquidity.usd;
        const formattedMSTLiquidity = formatNumberWithCommas(liquidityMST);

        setMSTPriceSwapsicle(priceMST)
        setMSTLiquiditySwapsicle(formattedMSTLiquidity)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMSTSwapsicleData();
  }, []);


  React.useEffect(() => {
    const getUSDMSymmetricData = async () => {
      try {
        const response = await fetch("https://api.goldsky.com/api/public/project_clnbo3e3c16lj33xva5r2aqk7/subgraphs/symmetric-telos/prod/gn", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              query USDMPoolQuery {
                token(id: "0x8f7d64ea96d729ef24a0f30b4526d47b80d877b9") {
                  latestUSDPrice
                }
                pool(id: "0x5e99843486cf052baf0925a0cdeb40920477295900000000000000000000000b") {
                  id
                  symbol
                  totalLiquidity
                  totalLiquiditySansBPT
                }
              }
            `
          })
        });

        const responseData = await response.json();

        // Accessing the latestUSDPrice field from the token object
        const priceUSDM = responseData.data.token.latestUSDPrice;
        const roundedPriceUSDM = Math.round(priceUSDM * 10000) / 10000;  



        const liquidityUSDM = responseData.data.pool.totalLiquidity;
        const formattedUSDMLiquidity = Math.floor(Number(liquidityUSDM)).toLocaleString(); // Remove decimal places and add comma as thousands separator

        setUSDMPriceSymmetric(roundedPriceUSDM);
        setUSDMLiquiditySymmetric(formattedUSDMLiquidity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUSDMSymmetricData();
  }, []);


  React.useEffect(() => {
    const getMSTSymmetricData = async () => {
      try {
        const response = await fetch("https://api.goldsky.com/api/public/project_clnbo3e3c16lj33xva5r2aqk7/subgraphs/symmetric-telos/prod/gn", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
            query USDMPoolQuery {
              token(id: "0x568524da340579887db50ecf602cd1ba8451b243") {
                latestUSDPrice
                id
                pool {
                  id
                }
              }
              pool(id: "0x304970d2511aa3b121148afd387cfa623f551410000200000000000000000016") {
                id
                symbol
                totalLiquidity
                totalLiquiditySansBPT
              }
            }
            `
          })
        });

        const responseData = await response.json();

        // Accessing the latestUSDPrice field from the token object
        const priceMST = responseData.data.token.latestUSDPrice;
        const roundedPriceMST = Math.round(priceMST * 100) / 100; // Round to 2 decimal places

        const liquidityMST = responseData.data.pool.totalLiquidity;
        const formattedMSTLiquidity = Math.floor(Number(liquidityMST)).toLocaleString(); // Remove decimal places and add comma as thousands separator

        setMSTPriceSymmetric(roundedPriceMST);
        setMSTLiquiditySymmetric(formattedMSTLiquidity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMSTSymmetricData();
  }, []);

  const BaseUSDMData = [
    {
      img: image1,
      priceType: "USDM/TLOS",
      price: "$" + usdmPriceSwapsicle1,
      para: "Current Liquidity: $" + usdmLiquiditySwapsicle1,
      button: "Trade Now",
      href: "https://telos.swapsicle.io/swap?outputCurrency=0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9&inputCurrency=0x8D97Cea50351Fb4329d591682b148D43a0C3611b"
    },
    {
      img: image1,
      priceType: "USDM/USDC",
      price: "$" + usdmPriceSwapsicle2,
      para: "Current Liquidity: $" + usdmLiquiditySwapsicle2,
      button: "Trade Now",
      href: "https://telos.swapsicle.io/swap?outputCurrency=0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9&inputCurrency=0x8D97Cea50351Fb4329d591682b148D43a0C3611b"
    },
    {
      img: image2,
      priceType: "USDM/USDT-USDC",
      price: "$" + usdmPriceSymmetric,
      para: "Current Liquidity: $" + usdmLiquiditySymmetric,
      button: "Trade Now",
      href: "https://telos.symm.fi/#/telos/swap"

    },
  ];


  const BaseMSTData = [
    {
      img: image1,
      priceType: "MST/TLOS",
      price: "$" + mstPriceSwapsicle,
      para: "Current Liquidity: $" + mstLiquiditySwapsicle,
      button: "Trade Now",
      href: "https://telos.swapsicle.io/swap?outputCurrency=0x568524DA340579887db50Ecf602Cd1BA8451b243&inputCurrency=0x8D97Cea50351Fb4329d591682b148D43a0C3611b"

    },
    {
      img: image1,
      priceType: "MST/TLOS",
      price: "$" + mstPriceSymmetric,
      para: "Current Liquidity: $" + mstLiquiditySymmetric,
      button: "Trade Now",
      href: "https://telos.symm.fi/#/telos/swap"

    },
  ];


  const { toggle, setToggle } = useContext(ToggleContext);
  return (
    <div className="main">
      <div className="switchbtn">
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          MST
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            checked={toggle} // Set checked attribute based on toggle state
            onChange={() => setToggle(!toggle)} // Use onChange event to update toggle state
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            USDM
          </label>
        </div>
      </div>
      {toggle === true ? (
        <div className="tradeCards">
          {BaseUSDMData.map((e, i) => {
            return (
              <div className="card" key={i}>
                <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
                <p className="switchType">
                  {e.switch} {e.priceType}
                </p>
                <h1>{e.price}</h1>
                <p className="cardPara">{e.para}</p>
                <a
                  href={e.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>{e.button}</button>
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="tradeCards">
          {BaseMSTData.map((e, i) => {
            return (
              <div className="card" key={i}>
                <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
                <p className="switchType">
                  {e.switch} {e.priceType}
                </p>
                <h1>{e.price}</h1>
                <p className="cardPara">{e.para}</p>
                <a
                  href={e.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>{e.button}</button>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BaseTrade;
