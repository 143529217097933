import React from "react";
import { useTheme } from "../../ThemeContext";
import "./Team.scss";

export default function Team() {
  // darkmode light mode
  const { isDarkMode } = useTheme();
  const teams = [
    {
      img: "assets/team/img1.png",
      name: "TJ",
      occupation: "Founder & Main Developer",
    },
    {
      img: "assets/team/img2.png",
      name: "Yair Haimson",
      occupation: "Business Development",
    },
    {
      img: "assets/team/img3.svg",
      name: "David Alloush",
      occupation: "Investment Networking",
    },
    {
      img: "assets/team/img4.svg",
      name: "Dickson",
      occupation: "Community Manager",
    },
    {
      img: "assets/team/img5.svg",
      name: "Noam",
      occupation: "UI & UX Designer",
    },
  ];
  return (
    <section id="team-area">
      <div className="container">
        <div className={`${isDarkMode ? "dark" : "light"}`}>
          <h1>Meet The Team</h1>
          <p>
            With decades of collective experience, we unite to usher in a new
            era of robust and stable currency.
          </p>
          <div className="team-content-wrapper">
            {teams.map((t, i) => (
              <div className="team" key={i}>
                <div className="img-wrapper">
                  <img src={t.img} alt="" />
                </div>
                <h5>{t.name}</h5>
                <p>{t.occupation}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      { /* <Swap projectId="swing-project-id" /> */}
    </section>
  );
}
