import React, { useState } from "react";
import "../assets/style/tradepage.css";
import BaseTrade from "../Components/Trade/BaseTrade";
import TelosEVM from "../Components/Trade/TelosEVM";
import FuseTrade from "../Components/Trade/FuseTrade";
import shape from "../assets/Images/shape.png"
import Navbar from "../Components/Common/Navbar/Navbar";


const TradePage = () => {

    const [islink, setIsLink] = useState("base");

    return (

        <div className="trade">
            <Navbar />
            <div className="tradeTop" style={{ marginTop: '180px' }}> {/* Add margin-top */}
                <div className="topTitle">
                    <img src={shape} className="shapImage" alt="shape pic" />
                    <h1>Trade Meridian’s Assets Where it’s Convenient to You.</h1>
                </div>
                <p>
                    USDM is the stablecoin of Meridian, while MST serves as the secondary
                    token that enables users to earn protocol fees <br /> through staking.
                    Both tokens are now accessible across three different blockchain
                    networks.
                </p>
            </div>
            <div className="tradelinks" style={{ marginTop: '20px' }}> {/* Add margin-top */}
                <li
                    onClick={() => setIsLink("base")}
                    className={islink === "base" ? "isActive" : ""}
                >
                    Base
                </li>
                <li
                    onClick={() => setIsLink("teloTrade")}
                    className={islink === "teloTrade" ? "isActive" : ""}
                >
                    TelosEVM
                </li>
                <li
                    onClick={() => setIsLink("fuse")}
                    className={islink === "fuse" ? "isActive" : ""}
                >
                    Fuse
                </li>
            </div>
            {islink === "base" && <BaseTrade />}
            {islink === "teloTrade" && <TelosEVM />}
            {islink === "fuse" && <FuseTrade />}
        </div>
    );
};

export default TradePage;
