import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { Link, useLocation } from "react-router-dom";
import { CartIcon, MoonStars } from "../../../Icons";
import { HiOutlineMenu } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Offcanvas } from "react-bootstrap";
import { useTheme } from "../../../ThemeContext";

export default function Navbar() {
  // darkmode light mode
  const { isDarkMode, toggleTheme } = useTheme();
  //Active Nav

  //Offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activeNav, setActiveNav] = useState("Home");
  const location = useLocation();


  //navItems
  const navItems = [
    {
      name: "Home",
      link: "https://meridianfinance.net",
    },
    {
      name: "Token Portal",
      link: "https://stake.meridianfinance.net/#/tokens",
    },

    {
      name: "Roadmap",
      link: "https://docs.meridianfinance.net/welcome-to-meridian/roadmap",

    },
    {
      name: "Docs",
      link: "https://docs.meridianfinance.net/",
    },
  ];

  useEffect(() => {
    const activeNavItem = navItems.find(item => location.pathname.startsWith(item.link));
    if (location.pathname == "/tokens") {
      setActiveNav("Token Portal");
    }
  }, [location.pathname]);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="navbar-area" className={`${scroll ? "hide-nav" : ""}`}>
      <div
        className={`${isDarkMode ? "dark" : "light"}`}
        style={{
          padding: scroll ? "0px" : "20px", // Adjust padding when scrolling
          transition: "padding 0.3s ease", // Add transition for smooth effect
        }}
      >
        <div className={`navbar-content-wrapper`}>
          <div className="logo">
            <Link to="/" onClick={() => setActiveNav(navItems[0]?.name)}>
              <img src="assets/logo.svg" alt="" />
            </Link>
          </div>
          <nav>
            <ul>
              {navItems.map((item, i) => (
                <li key={i}>
                  <a
                    href={item.link.startsWith("http") ? item.link : `/${item.link}`}
                    className={`${activeNav === item.name && "activeNav"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(item.link, "_self");
                      setActiveNav(item.name);
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="btn-group">
            <button className="moon-btn" onClick={toggleTheme}>
              <MoonStars color={isDarkMode ? "#8692D0" : "#4B465C"} />
            </button>
          </div>
          <div className="small-device-menu">
            <button className="moon-btn" onClick={toggleTheme}>
              <MoonStars color={isDarkMode ? "#8692D0" : "#4B465C"} />
            </button>
            <HiOutlineMenu
              size={28}
              color={isDarkMode ? "#FFF" : "#000"}
              onClick={handleShow}
            />
            <>
              <Offcanvas
                show={show}
                onHide={handleClose}
                placement="end"
                className={`${isDarkMode ? "dark" : "light"}`}
              >
                <Offcanvas.Header>
                  <Offcanvas.Title>Menu</Offcanvas.Title>
                  <AiOutlineCloseCircle
                    size={28}
                    onClick={handleClose}
                    color={isDarkMode ? "#FFF" : "#000"}
                  />
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <nav>
                    <ul>
                      {navItems.map((item, i) => (
                        <li key={i}>
                          <a
                            href={item.link.startsWith("http") ? item.link : `/${item.link}`}
                            className={`${activeNav === item.name && "activeNav"}`}
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(item.link, "_self");
                              setActiveNav(item.name);
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Offcanvas.Body>
              </Offcanvas>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
