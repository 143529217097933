import React, { useContext } from "react";
// import { BaseUSDMData } from "../../Data";
import uniswapIMG from "../../assets/Images/Uniswap.svg";
import image2 from "../../assets/Images/Symmetric.png";
import { ToggleContext } from './ToggleContext';


const BaseTrade = () => {

  // darkmode light mode
  const [mstPriceUniswap, setMSTPriceUniswap] = React.useState(0);
  const [mstLiquidityUniswap, setMSTLiquidityUniswap] = React.useState(0);

  const [usdmPriceUniswap, setUSDMPriceUniswap] = React.useState(0);
  const [usdmLiquidityUniswap, setUSDMLiquidityUniswap] = React.useState(0);

  function formatNumberWithCommas(number) {
    return Math.floor(number).toLocaleString();
  }


  React.useEffect(() => {
    const getMSTUniswapData = async () => {
      try {
        const response = await fetch(
          "https://api.dexscreener.com/latest/dex/tokens/0x2F3b1A07E3eFb1fCc64BD09b86bD0Fa885D93552"
        );
        const data = await response.json();

        // Accessing the priceNative field from the first pair
        const priceMST = data.pairs[0].priceUsd;
        const liquidityMST = data.pairs[0].liquidity.usd;
        const formattedMSTLiquidity = formatNumberWithCommas(liquidityMST);

        setMSTPriceUniswap(priceMST)
        setMSTLiquidityUniswap(formattedMSTLiquidity)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMSTUniswapData();
  }, []);


  React.useEffect(() => {
    const getUSDMUniswapData = async () => {
      try {
        const response = await fetch(
          "https://api.dexscreener.com/latest/dex/tokens/0x5e06eA564efcB3158a85dBF0B9E017cb003ff56f"
        );
        const data = await response.json();

        // Accessing the priceNative field from the first pair
        const priceUSDM = data.pairs[0].priceUsd;
        const liquidityUSDM = data.pairs[0].liquidity.usd;
        const formattedUSDMLiquidity = formatNumberWithCommas(liquidityUSDM);


        setUSDMPriceUniswap(priceUSDM)
        setUSDMLiquidityUniswap(formattedUSDMLiquidity)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUSDMUniswapData();
  }, []);


  const BaseUSDMData = [
    {
      img: uniswapIMG,
      priceType: "USDM/USDC",
      price: "$" + usdmPriceUniswap,
      para: "Current Liquidity: $" + usdmLiquidityUniswap,
      button: "Trade Now",
      href: "https://app.uniswap.org/swap?exactField=input&exactAmount=0&outputCurrency=0x5e06eA564efcB3158a85dBF0B9E017cb003ff56f&inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913&chain=base"
    },
  ];


  const BaseMSTData = [
    {
      img: uniswapIMG,
      priceType: "MST/ETH",
      price: "$" + mstPriceUniswap,
      para: "Current Liquidity: $" + mstLiquidityUniswap,
      button: "Trade Now",
      href: "https://app.uniswap.org/swap?exactField=input&exactAmount=0&outputCurrency=0x2F3b1A07E3eFb1fCc64BD09b86bD0Fa885D93552&inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913&chain=base"
    },
  ];


  const { toggle, setToggle } = useContext(ToggleContext);
  return (
    <div className="main">
      <div className="switchbtn">
        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
          MST
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            checked={toggle} // Set checked attribute based on toggle state
            onChange={() => setToggle(!toggle)} // Use onChange event to update toggle state
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            USDM
          </label>
        </div>
      </div>
      {/* Render other components based on toggle value */}

      {toggle === true ? (
        <div className="tradeCards">
          {BaseUSDMData.map((e, i) => {
            return (
              <div className="card" key={i}>
                <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
                <p className="switchType">
                  {e.switch} {e.priceType}
                </p>
                <h1>{e.price}</h1>
                <p className="cardPara">{e.para}</p>
                <a
                  href={e.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>{e.button}</button>
                </a>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="tradeCards">
          {BaseMSTData.map((e, i) => {
            return (
              <div className="card" key={i}>
                <img src={e.img} alt="PIC" style={{ width: '200px', height: 'auto' }} />
                <p className="switchType">
                  {e.switch} {e.priceType}
                </p>
                <h1>{e.price}</h1>
                <p className="cardPara">{e.para}</p>
                <a
                  href={e.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>{e.button}</button>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BaseTrade;
