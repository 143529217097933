import React, { useEffect, useState } from "react";
import "./Statistics.scss";
import { useTheme } from "../../ThemeContext";
import { LockedShape, TrovesIcon, UserIcon, VolumeIcon } from "../../Icons";

const yieldImg = require('../../img/yield.png');

export default function Statistics() {
  // darkmode light mode);
  const { isDarkMode } = useTheme();

  const [tvl, setTvl] = useState(0);
  const [USDMYield, setYield] = useState(0);
  const [troves, setTroves] = useState(0);
  const [volume, setVolume] = useState("0");


  function formatNumberWithCommas(number) {
    return Math.floor(number).toLocaleString();
  }

  React.useEffect(() => {
    const getLiquidity = async () => {
      const response = await fetch(
        "https://api.llama.fi/protocol/meridian"
      );
      const data = await response.json();
      const baseTVL = data.currentChainTvls.Base
      const fuseTVL = data.currentChainTvls.Fuse
      const telosTVL = data.currentChainTvls.Telos
      const meterTVL = data.currentChainTvls.Meter
      const borrowed = data.currentChainTvls.borrowed
      const totalTVL = baseTVL + fuseTVL + telosTVL + meterTVL + borrowed
      const formattedTVL = formatNumberWithCommas(totalTVL);

      setTvl(formattedTVL);
      // setMstPrice(data);
    };
    getLiquidity();
  }, []);

  useEffect(() => {
    const getStabilityPoolYield = async () => {
      const response = await fetch(
        "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/stability_pool"
      );
      const data = await response.json();
      // Find the largest value in the response object
      const maxValue = Math.max(...Object.values(data));
      setYield(maxValue);
    };
  
    getStabilityPoolYield();
  }, []);

  useEffect(() => {
    const getTroves = async () => {
      const response = await fetch(
        "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/trove_count"
      );
      const data = await response.json();
      setTroves(data);
    };
    getTroves();
  }, []);

  useEffect(() => {
    const getVolume = async () => {
      const response = await fetch(
        "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/total_volume"
      );
      const data = await response.text();
      setVolume(data);
    };
    getVolume();
  }, []);


  return (
    <section id="statistics-area">
      <div className="container">
        <div className={`${isDarkMode ? "dark" : "light"}`}>
          <div className="statistics-content-wrapper">
            <div className="statistics-box">
              <span>statistics</span>
            </div>
            <h4>Meridian In the Numbers</h4>
            <div className="meridian-box-container">
              <div className="box">
                <div>
                  <LockedShape fill={isDarkMode ? "" : "#E3E1FC"} />
                </div>
                <h5>Total Value Locked</h5>
                <h2>${tvl.toLocaleString()}</h2>
              </div>
              <div className="box">
                <div>
                  <img src={yieldImg} alt="My Image" width="64" height="64" />
                </div>
                <h5>USDM Staking APR</h5>
                <h2>{USDMYield}%</h2>
              </div>
              <div className="box">
                <div>
                  <TrovesIcon />
                </div>
                <h5>Opened Troves</h5>
                <h2>{troves || 26}</h2>
              </div>
              <div className="box">
                <div>
                  <VolumeIcon fill={isDarkMode ? "" : "#FFECD9"} />
                </div>
                <h5>Trading Volume</h5>
                <h2> ${parseInt(volume.slice(0, 7)).toLocaleString()}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
